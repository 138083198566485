<template>
  <!--begin::Advance Table Widget 9-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark"></span>
      </h3>
      <div class="card-toolbar">
       <a href="#" class="btn btn-primary font-weight-bolder font-size-sm" @click.prevent="createMemberTypeClicked"
          ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>Ny Medlemstyp</a>
     </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0 pb-3">
      <!--begin::Table-->
      <div class="table-responsive">
        <table class="table table-head-custom table-vertical-center table-head-bg table-borderless">
          <thead>
            <tr class="text-left">
              <th>ID</th>
              <th>Namn på medlemstyp</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, i) in membertypes">
              <MemberTypesTableRow
                v-bind:key="i"
                :membertype="item"
                :currentCompanyId="currentCompanyId"
                @deleteMemberTypeClicked="deleteMemberTypeClicked"
                @selectMemberTypeClicked="selectMemberTypeClicked"
                @toastr="toastr"
              />
            </template>
          </tbody>
        </table>
      </div>
      <!--end::Table-->
    </div>
    <!--end::Body-->
  </div>
  <!--end::Advance Table Widget 9-->
</template>
<style lang="scss" scoped>

</style>

<script>
import axios from 'axios';
import MemberTypesTableRow from '@/view/pages/ml/system/MemberTypesTableRow.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


export default {
  name: 'membertypes-table',
  props: ['membertypes', 'currentCompanyId'],
  emits: ['createMemberTypeClicked', 'deleteMemberTypeClicked', 'selectMemberTypeClicked'],
  mixins: [ toasts ],
  components: {
    MemberTypesTableRow
  },
  methods: {
    createMemberTypeClicked() {
      this.$emit('createMemberTypeClicked');
    },
    deleteMemberTypeClicked(membertype) {
      this.$emit('deleteMemberTypeClicked', membertype);
    },
    selectMemberTypeClicked(membertype) {
      this.$emit('selectMemberTypeClicked', membertype);
    }
  },
  data() {
    return {};
  }
};
</script>

