<template>
  <div class="px-5 mt-8" id="page-membertypes">
    <b-row>
      <b-col lg="2"></b-col>
      <b-col lg="8">
        <MemberTypesTable
          :membertypes="local_membertypes"
          @createMemberTypeClicked="createMemberTypeClicked"
          @selectMemberTypeClicked="selectMemberTypeClicked"
          @deleteMemberTypeClicked="deleteMemberTypeClicked"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="2"></b-col>
      <b-col lg="8">
        <b-card v-if="creating || editing" title="Editera medlemstyp" class="mb-2 mt-8 edit-membertype" hide-footer>
          <div>
            <b-form class="mt-8" @submit="saveData">
              <b-form-group id="input-group-name" label="Namn" label-for="input-name">
                <b-form-input
                  id="input-name"
                  v-model="form.name"
                  type="text"
                  required
                ></b-form-input>
              </b-form-group>

              <b-button type="submit" variant="primary">Spara</b-button>

            </b-form>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<style lang="scss" scoped>

</style>


<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import MemberTypesTable from '@/view/pages/ml/system/MemberTypesTable.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


export default {
  name: 'membertypes-settings',
  components: {
    MemberTypesTable
  },
  mixins: [ toasts ],
  props: ['membertypes'],
  emits: ['createMemberType', 'updateMemberType', 'deleteMemberType'],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId'])
  },
  mounted() {
    if (this.membertypes) {
      this.local_membertypes = [ ...this.membertypes ];
    }
    this.loadData();
  },
  data() {
    return {
      local_membertypes: [],
      form: {
        name: ''
      },
      editing: false,
      creating: false,
    };
  },
  watch: {
    membertypes: {
      handler: function(val) {
        this.local_membertypes = [ ...val ];
      },
      deep: true
    },
    currentCompanyId(new_value) {
      if (new_value) {
        this.loadData();
      }
    },
    settings(new_value) {
      if (new_value) {
        this.local_membertypes = [ ...new_value.membertypes ];
      }
    }
  },
  methods: {
    async loadData() {

    },
    getLangText(lang_text, lang) {
      for (var i = 0; i < lang_text.length; ++i) {
        if (lang_text[i].lang === lang) {
          return lang_text[i].text;
        }
      }

      return 'Språköversättning saknas';
    },
    createMemberTypeClicked() {
      this.creating = true;
      this.form.name = "";
      this.form.level = 0;
      this.scrollToEditMemberType();
    },
    selectMemberTypeClicked(membertype) {
      this.form = membertype;

      this.editing = true;
      this.scrollToEditMemberType();
    },
    deleteMemberTypeClicked(membertype) {
      //this.titles = this.titles.filter(item => item.id !== title.id);

      this.$emit('deleteMemberType', membertype.id);
    },
    randomstr(length) {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;

      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }

      return result;
    },
    saveData(e) {
      e.preventDefault();

      if (this.creating) {
        this.$emit('createMemberType', this.form.name);
      } else {
        this.$emit('updateMemberType', this.form.id, this.form.name);

        this.editing = false;
      }
    },
    scrollToEditMemberType() {
      var that = this;
      setTimeout(function () {
        const el = that.$el.getElementsByClassName('edit-membertype')[0];

        if (el) {
          const yOffset = -70;
          const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      }, 100);
    },

  }
};
</script>
